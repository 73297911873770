import React, { useEffect, useState } from 'react'
import Card from '../Componentes/Card'
import { useAuth } from '../Context/Context';
import { StyledReporteActividades } from '../themes/StyledReporteActividades';
import useDescargarArchivos from '../hooks/useDescargarArchivos';
import Loader from '../Componentes/Loader';
import { useShowDataTable } from '../hooks/useShowDataTable';
import flechaAbajo from "../assets/svg/abajo.svg";
import { useFecha } from '../hooks/useFecha';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import InputPKT1 from '../Componentes/InputPKT1';
import SelectPKT1 from '../Componentes/SelectPKT1';
import useUsuarios from '../hooks/useUsuarios';
import { useLocalStorage } from '../hooks/useLocalStorage';

const ReporteActividades = () => {

    const { user } = useAuth();

    const { cambioSeccion } = useLocalStorage();

    const { DescargarArchivos, loading } = useDescargarArchivos();

    const [index, setIndex] = useState();

    const [reporteUsuario, setReporteUsuario] = useState("");

    const [showModalSelect, setShowModalSelect] = useState(false);

    const [userId, setUserId] = useState(0);

    const [Descripcion, setDescripcion] = useState("Todos");

    const { fechaActualMostrar, primerDiaMes, formatFecha } = useFecha();

    const [fechaInicio, setFechaInicio] = useState(primerDiaMes);

    const [fechaFin, setFechaFin] = useState(fechaActualMostrar);

    const { showUsuarios, dataSelect, cargando } = useUsuarios();

    const [tipoReporte, setTipoRepoorte] = useState([{
        id: 0,
        descripcion: "Actividades"
    }]);




    useEffect(() => {
        if (user.idusuario === 747) {
            showUsuarios(`${process.env.REACT_APP_API_PROYECTOS}Usuarios/Usuarios`);
        }

        cambioSeccion("Reportes");
    }, []);


    //Consumir otro servicio
    //https://reportesdesarrollo.paq1.com.mx/swagger/index.html
    //SoporteActividades

    const ImprimirReporteActividades = async () => {

        // if(user.idusuario === 747){
        //     await DescargarArchivos(`${process.env.REACT_APP_API_SOPORTE}ReporteActividades/ObtenerReporteActividades?busqueda=${reporteUsuario}&fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}`)
        // }else{
        //     await DescargarArchivos(`${process.env.REACT_APP_API_SOPORTE}ReporteActividades/ObtenerReporteActividades?busqueda=${user.nombreusuario}&fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}`)
        // }



        if (user.idusuario === 747) {
            await DescargarArchivos(`${process.env.REACT_APP_API_REPORTES_SOPORTE}SoporteActividades/Actividades/Detalles?IdUsuario=${userId}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`)
        } else {
            await DescargarArchivos(`${process.env.REACT_APP_API_REPORTES_SOPORTE}SoporteActividades/Actividades/Detalles?IdUsuario=${user.idusuario}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`)
        }
    }

    //seccion admin

    //  const cambiarUsuario = (usuario) => {

    //     if (usuario === 1) {
    //         setUserName("Admin")
    //         setReporteUsuario("Admin")
    //     } else if (usuario === 119) {
    //         setUserName("MarioLopez")
    //         setReporteUsuario("MarioLopez")
    //     } else if (usuario === 499) {
    //         setUserName("IrvingFrancisco")
    //         setReporteUsuario("IrvingFrancisco")
    //     } else if (usuario === 749) {
    //         setUserName("JoelBueno")
    //         setReporteUsuario("JoelBueno")
    //     } else if (usuario === 740) {
    //         setUserName("CarlosSoto")
    //         setReporteUsuario("CarlosSoto")
    //     } else if (usuario === 743) {
    //         setUserName("ErikaSilva")
    //         setReporteUsuario("ErikaSilva")
    //     } else if (usuario === 744) {
    //         setUserName("DanielHiguera")
    //         setReporteUsuario("DanielHiguera")
    //     } else if (usuario === 747) {
    //         setUserName("OscarOsorio")
    //         setReporteUsuario("OscarOsorio")
    //     } else if (usuario === 768) {
    //         setUserName("CarlosSandoval")
    //         setReporteUsuario("CarlosSandoval")
    //     }else if (usuario === 0) {
    //         setUserName("Todos")
    //         setReporteUsuario("")
    //     }
    //     setShowModalSelect(false);
    //     setUserId(usuario);

    // }

    const DatosSelect = (e) => {
        setIndex(e.target.selectedIndex);
        setUserId(e.target.value);
        setDescripcion(e.target.children[e.target.selectedIndex].text);
    }


    return (
        <StyledReporteActividades>
            <div className='CuerpoFormularioReporteActividades'>
                {loading || cargando ?
                    <Loader title="Reportes" />
                    :
                    <Card titulo="Reportes de Actividades">
                        <div className='ContenedorFormularioReportes'>
                            {user.idusuario === 747 ?
                                <SelectPKT1 label={"Usuarios"} OpcionPorDefecto={Descripcion} MapeoOpciones={dataSelect} MostrarOpcionPorDefecto="none" Filtrado={(e) => DatosSelect(e)} />
                                :
                                <InputPKT1 label={"Usuario"} value={user.nombreusuario} type={"text"} mostrar={"none"} desactivar={true} />
                            }
                            <div className='ContenedorHeaderReportes'>
                                <SelectPKT1 label={"Tipo de Reporte"} MapeoOpciones={tipoReporte} MostrarOpcionPorDefecto="none" value={0} OpcionPorDefecto={"Actividades"} />

                                <div className='ContenedorFechasReportes'>
                                    <InputPKT1 label={"Fecha Inicio"} type={"date"} mostrar={"none"} value={fechaInicio} Accion={(e) => setFechaInicio(e.target.value)} />
                                    <InputPKT1 label={"Fecha Fin"} type={"date"} mostrar={"none"} value={fechaFin} Accion={(e) => setFechaFin(e.target.value)} />
                                </div>

                            </div>
                        </div>
                        <div className='botonAccion'>
                            <BotonesAcciones accion={"Generar Reporte"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={ImprimirReporteActividades} />
                        </div>
                    </Card>
                }
            </div>
        </StyledReporteActividades>
    )
}
export default ReporteActividades