import React, { useEffect, useState } from 'react'
import Buscar from '../assets/svg/buscar.svg'
import Modal from '../Componentes/Modal';
import { StyledSeguimientoActividades } from '../themes/StyledSeguimientoActividades';
import { StyledActividades } from '../themes/StyledActividades';
import Loader from '../Componentes/Loader';
import { useSeguimiento } from '../hooks/useSeguimiento';
import { useFecha } from '../hooks/useFecha';
import { AuthProvider, useAuth } from '../Context/Context';
import add from '../assets/svg/cruz.svg';
import flechaAbajo from "../assets/svg/abajo.svg";
import { StyledDetallesActividades } from '../themes/StyledDetallesActividades';
import { useShowDataTable } from '../hooks/useShowDataTable';
import { Await, useLocation, useParams } from 'react-router-dom';
import { useShowNameStatus } from '../hooks/useShowNameStatus';
import opciones from '../assets/svg/opciones.svg';
import Editar from "../assets/svg/editar.svg";
import { useGuardar } from '../hooks/useGuardar';
import { click } from '@testing-library/user-event/dist/click';
import { useAlert } from '../hooks/useAlert';
import InputPKT1 from '../Componentes/InputPKT1';
import SelectPKT1 from '../Componentes/SelectPKT1';
import TextAreaPKT1 from '../Componentes/TextAreaPKT1';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import Agregar from '../Componentes/Agregar';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useValidacion } from '../hooks/useValidacion';



const ListadoSeguimiento = ({ props }) => {

    const [buscar, setBuscar] = useState("");
    const [showModalSeguimiento, setShowModalSeguimiento] = useState(false);
    const [mostrarSeguimientoOpciones, setMostrarSeguimientoOpciones] = useState(false);
    const [ids, setIds] = useState(0);
    const [mostrarSeguimientoBotones, setMostrarSeguimientoBotones] = useState("");
    const [objetoSeguimiento, setObjetoSeguimiento] = useState({});
    


    //Formulario de seguimiento de actividades
    const [tituloActividad, setTituloActividad] = useState("");
    const [activityId, setActivityId] = useState("");
    const [tiempoSeguimiento, setTiempoSeguimiento] = useState("");
    const [observation, setObservation] = useState("");
    const [showEstatus, setShowEstatus] = useState("Seleccionar..");
    const [showModalSelect, setShowModalSelect] = useState(false);
    const [actividadStatus, setActividadStatus] = useState(0);

    const [seguimientostatus, setSeguimientoStatus] = useState(0);

    const [ desactivarBoton, setDesactivarBoton ] = useState(false);


    //Llamada al hook para mostrar el usurio en linea
    const { user } = useAuth();
    const [username, setUserName] = useState(user.nombreusuario);
    const [userId, setUserId] = useState(user.userid);

    //Haciendo la llamada al custom hook que regresa el listado de actividades
    const { activityName, actividadCancelada, estatus, setStatus, detailData, loading, setLoading, setData, error, limitePaginas, showEditData, showDetailsData, editData } = useShowDataTable();



    const { formulario, agregarSeguimientoActividad, EstatusServicio, agregarSeguimientoSinCambioEstatus } = useSeguimiento();

    const { formatFecha, formatInsertFecha, formatShowDefaultFecha, formatShowFecha, fechaActualMostrar } = useFecha();

    //fecha de formulario de seguimiento de actividades
    const [seguimientoFecha, setSeguimientoFecha] = useState(fechaActualMostrar);

    //Recivir el id de otro componente
    const { id } = useParams();

    const { PaginaActual } = useLocalStorage();


    //Mostrar con nombre el estatus
    const { mostrarEstatus } = useShowNameStatus();


    const { guardarCambios } = useGuardar();

    const { alertError } = useAlert();


    const EstatusActividad = [
        {
            "id" : 1,
            "descripcion" : "En Proceso"
        },
        {
            "id" : 2,
            "descripcion" : "Pendientes",
        },
        {
            "id" : 3,
            "descripcion" : "Terminado",
        }
    ];

    // const [ValidarCamposs, setValidarCamposs] = useState({
    //     fecha: false,
    //     horas: false,
    //     estatus: false,
    //     descripcion: false
    // });

    let ObjetoValidador = {};

    const { validadorObjetos, VerificadorCampos, LimpiadorObjetoValidador } = useValidacion();


     //funcion para mostrar  y ocultar opciones con los 3 puntos de la tabla
     const mostrarOpciones = (id, estatusC) => {

        if (id === ids) {
            setIds("");
            

        } else {
            setIds(id);
            setMostrarSeguimientoOpciones(true);
        }
    }

    const ocultarOpciones = () =>{
        setIds("");
    }

    const EditarSeguimento =  async(e, idSeguimiento, estado) =>{
        setShowModalSeguimiento(true);
        setMostrarSeguimientoBotones("Editar Seguimiento");
        
        await showDetailsData(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalles/${id}`);

        let nombreEstado =  mostrarEstatus(estado);

        for(let i = 0; i < detailData.length; i++ ){
            if(detailData[i].id === idSeguimiento){
                let tiempo = detailData[i].minutosInvertidos/60;
                let observacion = detailData[i].observacion;
                let fecha = detailData[i].fechaInicial;
                setTiempoSeguimiento(tiempo);
                setObservation(observacion);
                setShowEstatus(nombreEstado);
                setSeguimientoFecha(fecha);

                setObjetoSeguimiento(detailData[i]);


            }
        }


        await showEditData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/${id}`);
    }


    const GuardarCambiosSeguimiento = async() => {

        let converitrTiempo = tiempoSeguimiento * 60;

        if(editData?.estatus !== estatus){
            let objetoEstatus = { 
                estatus : estatus
            };
            await guardarCambios(`${process.env.REACT_APP_API_SOPORTE}Actividades/Actualizar/${editData?.id}`, objetoEstatus);

            setShowModalSeguimiento(false);

            await showEditData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/${editData?.id}`);
        }


        let actualizacionSeguimiento = {
            ...objetoSeguimiento,
            observacion: observation,
            fechaInicial: seguimientoFecha,
            minutosInvertidos: converitrTiempo
        }

        let objetoSeguimientoActualizado = {};

        Object.keys(objetoSeguimiento).forEach((clave1) =>{
            if(objetoSeguimiento[clave1] !== actualizacionSeguimiento[clave1]){
                objetoSeguimientoActualizado[clave1] = actualizacionSeguimiento[clave1]; 
                    console.log(`${objetoSeguimientoActualizado[clave1]}`);
                }
            });

            console.log(objetoSeguimientoActualizado);

        if(Object.keys(objetoSeguimientoActualizado).length !== 0){

            await guardarCambios(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalle/Actualizar/${actualizacionSeguimiento.id}/${user.idusuario}`, objetoSeguimientoActualizado);

            setShowModalSeguimiento(false);

            await showDetailsData(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalles/${id}`);
        }
        
    }


    const MostrarSeguimientoModal = () => {
        setDesactivarBoton(false);
        setMostrarSeguimientoBotones("Guardar Seguimiento");
        setShowModalSeguimiento(true);
    }

    const cerrarModalSeguimiento = () => {
        limpiarSeguimiento();
        setShowModalSeguimiento(false);
    }

    useEffect(() => {

        showDetailsData(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalles/${id}`);

        showEditData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/${id}`);

        console.log(PaginaActual);

    }, []);

    const handleSeguimiento = async (e) => {

        console.log(showEstatus);


        let ValidadorDatosSeguimiento = {
            "horas": tiempoSeguimiento,
            "estatus": estatus,
            "descripcion": observation
        }

        // for (let propiedad in ValidadorDatosProyecto) {
        //     if (ValidadorDatosProyecto[propiedad] == "" || ValidadorDatosProyecto[propiedad] == undefined || ValidadorDatosProyecto[propiedad] == 0 ) {
        //         ObjetoValidador = {
        //             [propiedad]: true
        //         }
        //         VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
        //         setValidarCampos({ ...VerificadorCampos, [propiedad]: true })
        //     } else {
        //         ObjetoValidador = {
        //             [propiedad]: false
        //         }
        //         VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
        //         setValidarCampos({ ...VerificadorCampos, [propiedad]: false })
        //     }
        // }

        //Llamada a la funcion del hook validacion
        validadorObjetos(ValidadorDatosSeguimiento);


        
        if(!VerificadorCampos.fecha && !VerificadorCampos.horas && !VerificadorCampos.estatus && !VerificadorCampos.descripcion){

            e.preventDefault();
                let startDate = seguimientoFecha;
                let minutesSpent = tiempoSeguimiento * 60;
                //armar el objeto para el seguimiento de la actividad
                const formularioSeguimientoActividades = { 
                    "minutosInvertidos": minutesSpent, 
                    "observacion":observation, 
                    "fechaInicio":startDate 
                };
                setDesactivarBoton(true);
                if (estatus == editData?.estatus) {
                    let respSeguimiento = await agregarSeguimientoSinCambioEstatus(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalle/Agregar/${id}/${user.idusuario}`,
                        formularioSeguimientoActividades);
                    if(respSeguimiento !== 200){
                        setShowModalSeguimiento(true);
                        setDesactivarBoton(false);
                    }else{
                        setShowModalSeguimiento(false);
                        limpiarSeguimiento();
                    }
                    await showDetailsData(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalles/${id}`);
                    await showEditData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/${id}`);
                } else {
                        //armar el segundo objeto para llamar al segundo sevicio
                        let formularioActualizarStatus = { estatus }
                        console.log(formularioActualizarStatus)
                        let respSeguimiento = await agregarSeguimientoActividad(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalle/Agregar/${id}/${user.idusuario}`,
                            formularioSeguimientoActividades, `${process.env.REACT_APP_API_SOPORTE}Actividades/Actualizar/${id}`,
                            formularioActualizarStatus);
                        await showDetailsData(`${process.env.REACT_APP_API_SOPORTE}Actividades/Detalles/${id}`);
                        await showEditData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/${id}`);
                        if(respSeguimiento !== 200){
                            setShowModalSeguimiento(true);
                            setDesactivarBoton(false);
                        }else{
                            setShowModalSeguimiento(false);
                            limpiarSeguimiento();
                        }
                }
        } else { 

            alertError("Favor de llenar todos los campos");
            
        }

        
        

    }

    const limpiarSeguimiento = () => {
        setTiempoSeguimiento("");
        setObservation("");
        setSeguimientoFecha(fechaActualMostrar);
        setShowEstatus("Seleccionar...");
        setSeguimientoStatus(0);
        LimpiadorObjetoValidador();
    }

    const handleSegumientoEstatus = (seguimientoEstatus) => {
        setActividadStatus(seguimientoEstatus);
        if (seguimientoEstatus == "En proceso") {
            setShowEstatus("En proceso");
            setSeguimientoStatus(1);
            setShowModalSelect(false);
        } else if (seguimientoEstatus == "Pendiente") {
            setShowEstatus("Pendiente");
            setSeguimientoStatus(2);
            setShowModalSelect(false);
        } else if (seguimientoEstatus == "Terminado") {
            setShowEstatus("Terminado");
            setSeguimientoStatus(3);
            setShowModalSelect(false);
        }
    }

    const cambiarFormatoHora = (minuto) => {
        return minuto / 60;
    }

    const ocultarListadoSelect = () => {
        setShowModalSelect(false);
    }
    

    return (
        <StyledDetallesActividades>
            {loading ?
                <div className='PositionLoader'>
                    <Loader title="Seguimiento" />
                </div>
                :
                <div className='scroll'>
                    <div className='bodyRegistroActividades'>
                        <div className='acomodo'>
                            {/* <div className={cambiarBoton ? `filtroBuscar buscar` : `buscar`}>
                            <label>Titulo de actividad</label>
                            <div className='inputBuscar'>
                                <input type="text" placeholder='Buscar' onChange={(e) => setBuscar(e.target.value)} />
                                <button type='button' onClick={handleSearch}><img src={Buscar} onClick={handleSearch} /></button>
                            </div>
                        </div> */}
                            {/* <div className={actividadCancelada || estatus === 4 || estatus === 3 ? "actividadesCanceladas" : 'agregar'} onClick={() => MostrarSeguimientoModal()}>
                                <button className={actividadCancelada || estatus === 4 || estatus === 3 ? "noAgregarActividadesCanceladas" : 'criculoArgegar'} >
                                    <img src={add} alt="Agregar" />
                                </button>
                            </div> */}

                            <Agregar IconoAgregar = {add} Accion={MostrarSeguimientoModal} estatus = {estatus} actividadCancelada = {actividadCancelada} />
                        </div>
                        <div className='table'>
                            <div className='headerTable'>
                                <p className='MId'>ID</p>
                                <p className='MFechaRegistro'>Fecha de registro</p>
                                <p className='MTiempo'>Tiempo invertido</p>
                                <p className='MFechaSeguimiento'>Fecha seguimiento</p>
                                <p className='MDescripcion'>Descripción</p>
                                <p className='MEstatusActividad'>Estatus Actividad</p>
                                <p></p>
                            </div>
                            <div className='bodyTable'>
                                {detailData?.length !== 0 ?
                                    detailData?.map((item) => (
                                        <div className='col' key={item.id} onMouseLeave={ocultarOpciones}>
                                            <p className='DId'>{item.id}</p>
                                            <p className='fecha DFechaRegistro'>{formatShowFecha(item.fecha)}</p>
                                            <p className='DTiempo'>{cambiarFormatoHora(item.minutosInvertidos)}H</p>
                                            <p className='fecha DFechaSeguimiento'>{formatShowFecha(item.fechaInicial)}</p>
                                            <p className='observation DDescripcion'>{item.observacion}</p>
                                            <p className='DEstatusActividad'>{mostrarEstatus(editData?.estatus)}</p>
                                            <div className={ids === item.id ? `seleccionarOpcionesRegistro seleccionarOpciones` : `seleccionarOpciones`} onClick={() => mostrarOpciones(item.id, estatus)}>
                                                <img className='fondoOpciones' src={opciones} />
                                                {mostrarSeguimientoOpciones &&
                                                    <div className={ detailData?.length < 7 ? "ListadoOpciones opciones" : "MoverArriba opciones"} id='ListadoSeguimiento' style={ids === item.id ? { display: "block" } : { display: 'none' }} onClick={(e) => EditarSeguimento(e, item.id, estatus) }>
                                                        <div className='SelectOpciones' onMouseLeave={ocultarOpciones}>
                                                            <div className='opcionSeguimiento'>
                                                                <div className='iconoEditar'>
                                                                    <img src={Editar} alt="IconoEditar" />
                                                                </div>
                                                                <p>Editar Seguimiento</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )) : (<div className='nullData'> <p> No se encontraron datos para mostrar </p></div>)}
                            </div>
                        </div>
                    </div>
                    <Modal estado={showModalSeguimiento} cambiarEstado={() => cerrarModalSeguimiento()} titulo={PaginaActual}>
                        <StyledSeguimientoActividades>
                            <form className='ContenedorFormularioSeguimiento'>
                                <div className='grupoEditar'>
                                
                                    <InputPKT1 CampoValidado={VerificadorCampos.fecha} label={"Fecha"} type={"date"} mostrar={"none"} value ={seguimientoFecha} Accion={(e) => setSeguimientoFecha(e.target.value)} limite={fechaActualMostrar} />
                                    <InputPKT1 CampoValidado={VerificadorCampos.horas} label={"Horas"} placeholder={"0"} type={"number"} mostrar={"none"} value ={tiempoSeguimiento} Accion={(e) => setTiempoSeguimiento(e.target.value)} minimo={0} />
                                    { mostrarSeguimientoBotones == "Editar Seguimiento" ?
                                        <SelectPKT1  label={"Estatus"} OpcionPorDefecto={mostrarEstatus(editData?.estatus)} MapeoOpciones={EstatusActividad} MostrarOpcionPorDefecto="none" Filtrado={(e) => setStatus(e.target.value)} value={estatus} />
                                        :
                                        <SelectPKT1 CampoValidado={VerificadorCampos.estatus} label={"Estatus"} OpcionPorDefecto={"Seleccionar estatus..."} MapeoOpciones={EstatusActividad} MostrarOpcionPorDefecto="none" Filtrado={(e) => setStatus(e.target.value)} value={estatus} />
                                    }
                                </div>

                                <div className='registroActividad'>
                                    <TextAreaPKT1 CampoValidado={VerificadorCampos.descripcion} label={"Observación de actividades"} size={200} value={observation} onchange={(e) => setObservation(e.target.value)} />
                                </div>


                                <div className='buttons'>
                                    { mostrarSeguimientoBotones !== "Editar Seguimiento" ?
                                        <BotonesAcciones accion={"Guardar"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={handleSeguimiento} desactivar={desactivarBoton} />
                                        :
                                        <BotonesAcciones accion={"Guardar Cambios"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={GuardarCambiosSeguimiento} />
                                    }
                                </div>
                            </form>
                        </StyledSeguimientoActividades>
                    </Modal>
                </div>
            }
        </StyledDetallesActividades>

    )
}

export default ListadoSeguimiento