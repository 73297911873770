import styled from 'styled-components';
export const StyledActividades = styled.div`

.bodyRegistroActividades{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerTable{
    display: flex;
    border: #666770 2px solid;
    background-color: var(--HeaderTabla);
    background-size: auto;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    align-items: center;
    font-weight: bold;
}

.table{
    width: 100%;
}

.headerTable h3{
    margin-right: 15px;
}

.bodyTable{
    background: #FFF;
    border: #666770 2px solid;
    border-top: none;
    text-align: center;
    align-items: center;
    overflow: auto;
    scrollbar-width: none; /* Para Firefox */
    -ms-overflow-style: none; /* Para Internet Explorer y Edge */
}

.bodyTable .col{
    position: relative;
    display: flex;
    align-items: center;
}



.acomodo{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    align-items: center;

}

.buscar input{
    padding: 10px;
    border: #CCCCCC 2px solid;
    border-radius: 5px;
    outline: none;
    width: 30rem;
}

.buscar button{
    border: none;
    border-radius: 5px;
    background-color: var(--HeaderTabla);
    padding: 10px;
    margin-left: 1rem;
    cursor: pointer;
}

/* .buscar button img{
    width: 15px;
} */

.ContenedorAgregar{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.filtroBuscar button{
    background: #3BAF29;
}

.col{
    border-bottom: #666770 2px solid;
}

.col:last-child{
    border: none;
}

.seleccionarOpciones{
    width: 2rem;
}

/* .agregar img{
    cursor: pointer;
} */

.opciones{
    position: absolute;
    background-color: #DBDDED;
    border-radius: 5px;
    color: var(--HeaderTabla);
    z-index: 1;
    right: 0;
    top: 2rem;
}

.DesactivarOpciones{
    display: none;
}

.bodyTable .col:last-of-type:last-child .MoverArriba{
    top: -7.5rem;
}

.bodyTable .col:nth-last-child(2):not(:last-child) .MoverArriba{
    top: -7.5rem;
}

.bodyTable .col:nth-last-child(3):not(:last-child) .MoverArriba{
    top: -7.5rem;
}

.bodyTable p{
    margin: 0;
    font-size: 14px;
}

.opciones p{
    margin-top: -1%;
    margin: 0 auto;
}

.opciones img{
    width: 100%
}



.ContendorOpciones{
    width: 1.5rem;
    padding: 5px;
    border-radius: 20px;
}

.ContendorOpciones:hover{
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
}

.seleccionarOpcionesRegistro{
    position: relative;
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
}


.iconoSeguimiento{
    background-color: #FFF;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
    padding: 5px;
    width: 1.5rem;
}

.iconoEditar{
    background-color: #DDBD14;
    justify-content: center;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
    padding: 5px;
    width: 1.5rem;
}

.iconoEliminar{
    background-color: #D60000;
    justify-content: center;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
    padding: 5px;
    width: 1.5rem;
}

.opcionSeguimiento{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 1rem;

}

.opcionSeguimiento:hover{
    background: var(--HeaderTabla);
    color: var(--Blanco);
    border-radius: 5px;

}

.cambioRegistros{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;
    align-items: center;
}

.nullData{
    padding: 10px;
    text-align: center;
}

.ContenedorFiltros{
    display: flex;
    gap: 1rem;
}

.actividadesCanceladas{
    background-color: lightgray;
    display: flex;
    align-items: center;
    pointer-events: none;
}


@media only screen and (min-width: 300px){

    .MProyecto{
        display: none;
    }

    .DProyecto{
        display: none;
    }

    .MFechaActividad{
        display: none;
    }

    .DFechaActividad{
        display: none;
    }

    .MUsuario{
        display: none;
    }

    .DUsuario{
        display: none;
    }

    .MRegistro{
        display: none;
    }

    .DRegistro{
        display: none;
    }

    .MEstatus{
        display: none;
    }

    .DEstatus{
        display: none;
    }

    .headerTable{
        display: flex;
    }

    .acomodo{
        flex-wrap: wrap;
        gap: 1rem;
    }

    .headerTable .MId{
        flex: 1;
    }

    .col .DId{
        flex: 1;
    }

    .headerTable .MAcciones{
        flex: 1;
    } 

    .ContenedorOpciones{
        flex: 1;
    }

    .headerTable .MActividad{
        flex: 3;
    }

    .col .DActividad{
        flex:  3;
        text-align: start;
    }

    .cambioRegistros{
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    .bodyTable{
        height: 32vh;
    }

    


}

@media only screen and (min-width: 450px){

    .MEstatus{
        display: flex;
    }

    .DEstatus{
        display: flex;
    }

    .headerTable .MEstatus{
        flex: 2;
    }

    .col .DEstatus{
        flex: 2;
    }

    .headerTable .MActividad{
        flex: 10;
    }

    .col .DActividad{
        flex:  10;
    }

    .cambioRegistros{
        display: flex;
        justify-content: space-between;
    }
}



@media only screen and (min-width: 600px){

    .MEstatus{
        display: flex;
    }

    .DEstatus{
        display: flex;
    }

    .headerTable .MEstatus{
        flex: 1;
    }

    .col .DEstatus{
        flex: 1;
    }

    .headerTable .MActividad{
        flex: 6;
    }

    .col .DActividad{
        flex:  6;
    }

}

@media only screen and (min-width: 700px){

    .cambioRegistros{
        display: flex;
        justify-content: space-between;
    }

    .MProyecto{
        display: block;
    }

    .DProyecto{
        display: block;
    }

    .headerTable .MProyecto{
        flex: 6;
    }

    .col .DProyecto{
        flex:  6;
    }

}

@media only screen and (min-width: 900px){

    .MFechaActividad{
        display: block;
    }
    
    .DFechaActividad{
        display: block;
        justify-content: center;
    }

    .headerTable .MFechaActividad{
        flex: 4;
    } 

    .col .DFechaActividad{
        flex: 4;
    }

    .headerTable .MEstatus{
        flex: 2;
    }

    .col .DEstatus{
        flex: 2;
    }

    .bodyTable{
        height: 53vh;
    }



}

@media only screen and (min-width: 1200px) {

    .MProyecto{
        display: block;
    }
    
    .DProyecto{
        display: block;
    }

    .MFechaActividad{
        display: block;
    }

    .DFechaActividad{
        display: block;
    }

    .MUsuario{
        display: block;
    }

    .DUsuario{
        display: block;
    }

    .MRegistro{
        display: block;
    }

    .DRegistro{
        display: block;
    }

    .headerTable .MProyecto{
        flex: 5;
    }

    .col .DProyecto{
        flex: 5;
    }

    .headerTable .MRegistro{
        flex: 4;
    }

    .col .DRegistro{
        flex: 4;
    }

    .headerTable .MActividad{
        flex: 12;
    }

    .col .DActividad{
        flex:  12;
    }

    .headerTable .MUsuario{
        flex: 3;
    }

    .col .DUsuario{
        flex: 3;
    }

    .headerTable .MFechaActividad{
        flex: 4;
    } 

    .col .DFechaActividad{
        flex: 4;
    }

    .headerTable .MEstatus{
        flex: 2;
    } 

    .col .DEstatus{
        flex: 2;
    }

    .headerTable .MAcciones{
        flex: 1;
    } 

    .ContenedorOpciones{
        flex: 1;
    }



}

@media only screen and (min-width: 1920px) {

    
    

    
}
`