import axios from "axios"
import { useState } from "react"
import { useAlert } from "./useAlert";


export const useSeguimiento = () => {
  const [formulario, setFormulario] = useState(null);
  const { alertSave, alertError } = useAlert();
  const [EstatusServicio, setEstatusServicio] = useState(0);


    const agregarSeguimientoActividad = async (url, data, urlStatus, actividad) =>{
        try {
          let respuestaSeguimiento = await axios.post(url, data)
          let respuestaCambiosEstatus = await axios.put(urlStatus, actividad)
          alertSave("Seguimiento de actividad registrada");
          return(200)
        } catch (error) {
          console.log(error.response.data.message);
          alertError(error.response.data.message);
          setEstatusServicio(error.request.status);
          return(error.request.status)
        }
    }

    const agregarSeguimientoSinCambioEstatus = async (url, data) =>{
        try {
          let respuestaSeguimiento = await axios.post(url, data)
          alertSave("Seguimiento de actividad registrada");
          return(200)
        } catch (error) {
          console.log(error.response.data.message);
          alertError(error.response.data.message);
          setEstatusServicio(error.request.status);
          return(error.request.status)
        }
    }
  return {
    formulario,
    agregarSeguimientoActividad,
    EstatusServicio,
    agregarSeguimientoSinCambioEstatus

  }
}