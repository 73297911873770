import { useState } from "react"

/*
*/

export const useSelectPKT1 = () => {

    let propiedad1;
    let propiedad2;
    // const []

  const ConvertirASelect = (ArrayBase, configID, configDescripcion) => {

    const NuevaRespuesta = ArrayBase.map((resp, item) => {

        resp[item] = item

        const ConvertirAObjeto = resp;

        for(let propiedad in ConvertirAObjeto){
            if(propiedad == configID){
                propiedad1 = propiedad
            }
            if(propiedad == configDescripcion){
                propiedad2 = propiedad
            }
            if(ConvertirAObjeto[propiedad1] != ConvertirAObjeto[propiedad1 + 1] || ConvertirAObjeto[propiedad1]!== undefined  ){
                console.log({"id" : ConvertirAObjeto[propiedad1], "descripcion" : ConvertirAObjeto[propiedad2]})
            }


        }

        // const RehacerObjeto = { "id": resp.idUsuario, "descripcion":resp.nombre }
        // return RehacerObjeto
      })

    //   for (let propiedad in NuevaRespuesta){
    //     console.log([propiedad])
    //   }

  }

  return {
    ConvertirASelect
  }

}